import { NgModule, ModuleWithProviders} from '@angular/core';
import { CommonModule } from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {TranslateModule} from '@ngx-translate/core';

import {CardModule} from 'primeng/card';
import {AccordionModule} from 'primeng/primeng';
import {AutoCompleteModule} from 'primeng/primeng';
import {BreadcrumbModule} from 'primeng/primeng';
import {ButtonModule} from 'primeng/primeng';
import {CalendarModule} from 'primeng/primeng';
import {CarouselModule} from 'primeng/primeng';
import {ColorPickerModule} from 'primeng/primeng';
import {ChartModule} from 'primeng/primeng';
import {CheckboxModule} from 'primeng/primeng';
import {ChipsModule} from 'primeng/primeng';
import {CodeHighlighterModule} from 'primeng/primeng';
import {ConfirmDialogModule} from 'primeng/primeng';
import {ContextMenuModule} from 'primeng/primeng';
import {DataGridModule} from 'primeng/primeng';
import {DataListModule} from 'primeng/primeng';
import {DataScrollerModule} from 'primeng/primeng';
import {DataTableModule} from 'primeng/primeng';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/primeng';
import {DragDropModule} from 'primeng/primeng';
import {DropdownModule} from 'primeng/primeng';
import {EditorModule} from 'primeng/primeng';
import {FieldsetModule} from 'primeng/primeng';
import {FileUploadModule} from 'primeng/primeng';
import {GalleriaModule} from 'primeng/primeng';
import {GMapModule} from 'primeng/primeng';
import {GrowlModule} from 'primeng/primeng';
import {InputMaskModule} from 'primeng/primeng';
import {InputSwitchModule} from 'primeng/primeng';
import {InputTextModule} from 'primeng/primeng';
import {InputTextareaModule} from 'primeng/primeng';
import {LightboxModule} from 'primeng/primeng';
import {ListboxModule} from 'primeng/primeng';
import {MegaMenuModule} from 'primeng/primeng';
import {MenuModule} from 'primeng/primeng';
import {MenubarModule} from 'primeng/primeng';
import {MessagesModule} from 'primeng/primeng';
import {MessageModule} from 'primeng/primeng';
import {MultiSelectModule} from 'primeng/primeng';
import {OrderListModule} from 'primeng/primeng';
import {OrganizationChartModule} from 'primeng/primeng';
import {OverlayPanelModule} from 'primeng/primeng';
import {PaginatorModule} from 'primeng/primeng';
import {PanelModule} from 'primeng/primeng';
import {PanelMenuModule} from 'primeng/primeng';
import {PasswordModule} from 'primeng/primeng';
import {PickListModule} from 'primeng/primeng';
import {ProgressBarModule} from 'primeng/primeng';
import {RadioButtonModule} from 'primeng/primeng';
import {RatingModule} from 'primeng/primeng';
import {ScheduleModule} from 'primeng/primeng';
import {ScrollPanelModule} from 'primeng/primeng';
import {SelectButtonModule} from 'primeng/primeng';
import {SidebarModule} from 'primeng/sidebar';
import {SlideMenuModule} from 'primeng/primeng';
import {SliderModule} from 'primeng/primeng';
import {SpinnerModule} from 'primeng/primeng';
import {SplitButtonModule} from 'primeng/primeng';
import {StepsModule} from 'primeng/primeng';
import {TableModule} from 'primeng/table';
import {TabMenuModule} from 'primeng/primeng';
import {TabViewModule} from 'primeng/primeng';
import {TerminalModule} from 'primeng/primeng';
import {TieredMenuModule} from 'primeng/primeng';
import {ToggleButtonModule} from 'primeng/primeng';
import {ToolbarModule} from 'primeng/primeng';
import {TooltipModule} from 'primeng/primeng';
import {TreeModule} from 'primeng/primeng';
import {TreeTableModule} from 'primeng/primeng';
import {ToastModule} from 'primeng/toast';
import {ProgressSpinnerModule} from 'primeng/progressspinner'
import {SharedModule as PrimeSharedModule} from "primeng/primeng";

import {MessageService} from 'primeng/api';
import {HeaderInterceptorService} from '../service/header-interceptor.service';
import {AuthService} from '../service/auth.service';
import {ApiService} from '../service/api.service';
import {LoaderService} from '../service/loader.service';
import {TranslatorService} from '../service/translator.service';
import {ExcelService} from '../service/excel.service';
import { SafeUrlPipe } from '../pipe/safe-url.pipe';
import { LoaderComponent } from '../page/loader/loader.component';

@NgModule({
  declarations: [SafeUrlPipe, LoaderComponent],
  imports: [
    CommonModule,
    // AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    // ColorPickerModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    // CodeHighlighterModule,
    ConfirmDialogModule,
    // ContextMenuModule,
    // DataGridModule,
    // DataListModule,
    // DataScrollerModule,
    // DataTableModule,
    DataViewModule,
    DialogModule,
    // DragDropModule,
    DropdownModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    GalleriaModule,
    GMapModule,
    // GrowlModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    // LightboxModule,
    // ListboxModule,
    // MegaMenuModule,
    MenuModule,
    // MenubarModule,
    MessagesModule,
    MessageModule,
    MultiSelectModule,
    // OrderListModule,
    // OrganizationChartModule,
    OverlayPanelModule,
    // PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    // PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    // RatingModule,
    // ScheduleModule,
    ScrollPanelModule,
    // SelectButtonModule,
    SidebarModule,
    // SlideMenuModule,
    SliderModule,
    // SpinnerModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    // TerminalModule,
    // TieredMenuModule,
    ToastModule,
    // ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    ProgressSpinnerModule,
    // PrimeSharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports : [
    CommonModule,
    // AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    // ColorPickerModule,
    ChartModule,
    CheckboxModule,
    // ChipsModule,
    // CodeHighlighterModule,
    ConfirmDialogModule,
    // ContextMenuModule,
    // DataGridModule,
    // DataListModule,
    // DataScrollerModule,
    // DataTableModule,
    DataViewModule,
    DialogModule,
    // DragDropModule,
    DropdownModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    GalleriaModule,
    GMapModule,
    // GrowlModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    // LightboxModule,
    // ListboxModule,
    // MegaMenuModule,
    MenuModule,
    // MenubarModule,
    MessagesModule,
    MessageModule,
    MultiSelectModule,
    // OrderListModule,
    // OrganizationChartModule,
    OverlayPanelModule,
    // PaginatorModule,
    PanelModule,
    // PanelMenuModule,
    PasswordModule,
    // PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    // RatingModule,
    // ScheduleModule,
    ScrollPanelModule,
    // SelectButtonModule,
    SidebarModule,
    // SlideMenuModule,
    SliderModule,
    // SpinnerModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    // TerminalModule,
    // TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    // PrimeSharedModule,
    ProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SafeUrlPipe,
    LoaderComponent
  ]
})
export class SharedModule {
  //
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        MessageService, LoaderService, ApiService, AuthService, TranslatorService, ExcelService,
        {provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptorService, multi: true }
      ]
    };
  }
}
