import {Component, Inject, OnInit, forwardRef} from '@angular/core';
import {AppComponent} from './app.component';
import {ApiService, AuthService} from './service';
import {Config} from './guard';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
    User : any
    UriDashboard : any
    srcLogo:string;
    srcUser: string;
    displayPassword: boolean = false;
    displayProfile: boolean = false;
    summaryProfile: any;
    dateNew : any = ''
    roleName: any = 'ADMINISTRATOR'
    fullName: any = ''
    email: any = ''
    bidangName: any = ''
    constructor(
      public app: AppComponent,
      private apiService: ApiService,
      public authService: AuthService
    ) { }

    ngOnInit() {
      this.srcLogo = Config.get().apiBackend + 'public/logo/logo/png';
      this.srcUser = Config.get().apiBackend + 'public/logo/default_photo/png';
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun",
        "Jul", "Agu", "Sep", "Okt", "Nov", "Des"
      ];
      let date = new Date();
      this.dateNew = monthNames[date.getMonth()] + ' '+ date.getDate() + ', ' + date.getFullYear();
      // let data = JSON.parse(localStorage.getItem('user-profile'));
      // this.User = data.UserEmail;
      let dt = JSON.parse(localStorage.getItem("user-profile"))
      let txt = dt.nama_role
      this.roleName = txt.toUpperCase()
      this.fullName = dt.nama_lengkap
      this.email = dt.email
      this.bidangName = dt.nama_bidang
      this.UriDashboard = '#';
    }

    changePassword(){
      this.displayPassword = true;
    }

    showProfile(){
      this.displayProfile = true;
      let userdata = JSON.parse(localStorage.getItem('user-profile'));
      this.summaryProfile = {
        fullname: userdata.name,
        phone: userdata.phone,
        email: userdata.email
      }
    }


}
