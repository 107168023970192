import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent, NotFoundComponent, AccessDeniedComponent, LoginComponent} from './page';
import {AuthGuard, LoginGuard} from './guard';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
        RouterModule.forRoot([
          {canActivate: [AuthGuard], path: '', component: DashboardComponent},
          {canActivate:[LoginGuard], path: 'login', component: LoginComponent},
          {canActivate:[AuthGuard] ,path: 'user', loadChildren: './page/master/user/user.module#UserModule' },
          {canActivate:[AuthGuard] ,path: 'berita', loadChildren: './page/informasi/berita/berita.module#BeritaModule' },
          {canActivate:[AuthGuard] ,path: 'client', loadChildren: './page/oss/klien/fitur.module#KlienModule' },
          {canActivate:[AuthGuard] ,path: 'dokumen', loadChildren: './page/oss/dokumen/fitur.module#DokumenModule' },
          {canActivate:[AuthGuard] ,path: 'layanan', loadChildren: './page/oss/jenis-dokumen/fitur.module#JenisDokumenModule' },
          {canActivate:[AuthGuard] ,path: 'pegawai', loadChildren: './page/oss/pegawai/fitur.module#PegawaiModule' },
          {canActivate:[AuthGuard] ,path: 'kategori-bidang', loadChildren: './page/oss/kategori-bidang/fitur.module#KategoriBidangModule' },
          {canActivate:[AuthGuard] ,path: 'proses-kerja', loadChildren: './page/oss/proses-kerja/fitur.module#ProsesKerjaModule' },
          {canActivate:[AuthGuard] ,path: 'surat-pemohon', loadChildren: './page/oss/surat-pemohon/fitur.module#SuratPermohonanModule' },
          // {canActivate:[AuthGuard] ,path: 'tracking-dokumen', loadChildren: './page/oss/tracking-dokumen/fitur.module#TrackingDokumenModule' },
          {canActivate:[AuthGuard] ,path: 'bidang', loadChildren: './page/oss/bidang/fitur.module#BidangModule' },
          {canActivate:[AuthGuard] ,path: 'faq', loadChildren: './page/oss/faq/fitur.module#FaqModule' },
          {canActivate:[AuthGuard] ,path: 'pengajuan', loadChildren: './page/oss/pengajuan/fitur.module#PengajuanModule' },
          {canActivate:[AuthGuard] ,path: 'pengaduan', loadChildren: './page/oss/pengaduan/fitur.module#PengaduanModule' },
          {canActivate:[AuthGuard] ,path: 'sanksi', loadChildren: './page/oss/sanksi/fitur.module#SanksiModule' },
          {canActivate:[AuthGuard] ,path: 'role', loadChildren: './page/setting/role-user/role-user.module#RoleUserModule' },
          {canActivate:[AuthGuard] ,path: 'permission', loadChildren: './page/setting/menu/menu.module#MenuModule' },
          {canActivate:[AuthGuard] ,path: 'role-permission', loadChildren: './page/setting/role-menu/role-menu.module#RoleMenuModule' },
          {path: 'not-found', component: NotFoundComponent},
          {path: 'access-denied', component: AccessDeniedComponent},
          // kalo gak ada page not found
          { path: '**', redirectTo: 'not-found' }
        ])
    ],
    exports: [RouterModule]
})
export class AppRoutes {}
