import { Component, OnInit } from '@angular/core';
import { ApiService, TranslatorService} from '../../../service';
import { Config} from '../../../guard';
import { LazyLoadEvent, ConfirmationService} from 'primeng/primeng'
import { MessageService} from 'primeng/api';
import { Validators, FormBuilder, FormGroup} from '@angular/forms';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.css'],
	providers: [ConfirmationService]
})
export class UserComponent implements OnInit {
	dataTable: any[]
	totalRecords: number
	loading: boolean
	column: any
	sort: any
	page: number
	rows: number
	pencarian: string
	selected: any[]
	aktivIndex: number
	form: FormGroup
	filter: FormGroup
	items: any[]
	home: string
	bidangs: any[]
	roles: any[]
	displayDialog: boolean = false
	panelFilter: boolean = true
	constructor(private fb: FormBuilder,
		private apiService: ApiService,
		private messageService: MessageService,
		private terjemah: TranslatorService,
		private confirmationService: ConfirmationService) { }

	ngOnInit() {
		this.aktivIndex = 0
		this.page = Config.get().page
		this.rows = Config.get().rows
		this.column = ''
		this.sort = ''
		this.pencarian = ''
		this.getDataTable(this.page, this.rows)
		this.getDropdown()
		this.items = [
			{label:'Beranda', url: '#'},
			{label:'Pengaturan', url: '/pengaturan'}
		  ];
		this.form = this.fb.group({
			id : [''],
			nama_lengkap: ['',Validators.required],
			email: ['',Validators.required],
			telepon: ['',Validators.required],
			role_id: ['',Validators.required],
			bidang_id: [''],
			is_active: ['', Validators.required]
		})
	}
	cari(){
		this.getDataTable(this.page, this.rows)
	}
	getDropdown(){
		this.apiService.get(Config.get().apiBackend+'/options/role').subscribe((res)=>{
			this.roles = []
			res.data.forEach((data : any)=>{
				this.roles.push({
					label:data.nama,
					value:data.kode
				})
			})
		})

		this.apiService.get(Config.get().apiBackend+'/options/bidang').subscribe((res)=>{
			this.bidangs = []
			this.bidangs.push({
				label:'Semua',
				value: ''
			})
			res.data.forEach((data : any)=>{
				this.bidangs.push({
					label:data.nama,
					value:data.kode
				})
			})
		})
	}

	setExpandCollapse($event){
		this.panelFilter = $event.collapsed
	}

	getDataTable(page,rows) {
		this.loading = true;
		if(page > 0){
			page = (page/rows) + 1
		}
		this.apiService.get(Config.get().apiBackend+'/admin/user?page='+page+'&limit='+rows).subscribe((res)=>{
			this.dataTable = res.data
			this.totalRecords = res.meta.count
			this.loading = false
		}, (_error: any) => {
				this.loading = false
		})
	}

	onRowDblClick(event, data){
		this.aktivIndex = 1;
		console.log('cek-data-user:', data);
		let dataGrid = {
				id: data.id,
				nama_lengkap: data.nama_lengkap,
				role_id: data.role_id,
				bidang_id: data.bidang_id,
				email: data.email,
				telepon : data.telepon,
				is_active : data.is_active
		};
		this.form.setValue(dataGrid)
	}

	lazyLoad(event: LazyLoadEvent) {
		let sort
		if (event.sortOrder == 1) {
			sort = 'asc'
		} else {
			sort = 'desc'
		}
		console.log(event);
		if (event.sortField != undefined) {
			this.getDataTable(event.first,event.rows)
		}else{
			this.getDataTable(event.first,event.rows)
		}
	}

	setAktifIndex(event) {
		this.aktivIndex = event.index
	}

	onSubmit(){
		this.save();
		// if (this.form.invalid) {
		// 	console.log('testing');
		// 		this.messageService.add({
		// 				key: 't-notif',
		// 				severity: 'warn',
		// 				summary: this.terjemah.getTranslate().kesalahan,
		// 				detail: this.terjemah.getTranslate().pesanKesalahanDataTidakLengkap
		// 		});
		// } else {
				// this.save();
		//}
	}

	batal(){
		this.displayDialog = false
		this.reset();
	}

	reset(){
		this.form.reset({
			id:'',
			nama_lengkap: '',
			email: '',
			telepon: '',
			role_id: '',
			bidang_id: '',
			is_active: ''
		})
	}

	resetFilter(){
		this.filter.reset({
			id: '',
			program : ''
		})
		this.getDataTable(0, 10)
	}

	save() {
			// this.confirmationService.confirm({
			// 		message: 'Simpan data?',
			// 		accept: () => {
							let data = this.form.value;
							let dataKirim = {
									id : data.id,
									role_id : data.role_id,
									bidang_id: data.bidang_id,
									nama_lengkap: data.nama_lengkap,
									email: data.email,
									telepon: data.telepon,
									is_active: data.is_active
							}
							if (dataKirim.id == '') {
								delete dataKirim.id
								this.apiService.add(Config.get().apiBackend + '/admin/user', dataKirim).subscribe(_res => {
										this.messageService.add({
												key: 't-notif',
												severity: 'success',
												summary: this.terjemah.getTranslate().sukses,
												detail: this.terjemah.getTranslate().pesanSuksesInputData
										});
										this.reset()
										this.displayDialog = false
										this.getDataTable(0, 10)
								});
							} else {
								this.apiService.update(Config.get().apiBackend + '/admin/user', dataKirim.id, dataKirim).subscribe(_res => {
										this.messageService.add({
												key: 't-notif',
												severity: 'success',
												summary: this.terjemah.getTranslate().sukses,
												detail: this.terjemah.getTranslate().pesanSuksesInputData
										});
										this.reset()
										this.displayDialog = false
										this.getDataTable(0, 10)
								});
							}
			// 		}
			// });
	}

	hapus() {

			// this.confirmationService.confirm({
			// 		message: 'Simpan data?',
			// 		accept: () => {
							let dataDelete = []
							for (let i = 0; i < this.selected.length; i++) {
									dataDelete.push(this.selected[i].id)
							}
							let dataKirim = {
									id : dataDelete,
							}
							this.apiService.delete(Config.get().apiBackend + '/admin/user', dataKirim.id).subscribe(_res => {
									this.messageService.add({
											key: 't-notif',
											severity: 'success',
											summary: this.terjemah.getTranslate().sukses,
											detail: this.terjemah.getTranslate().pesanSuksesHapusData
									});
									this.batal();
							});
							// this.apiService.add(Config.get().apiBackend + 'service/setting/user/delete', dataKirim).subscribe(_res => {
							// 		this.messageService.add({
							// 				key: 't-notif',
							// 				severity: 'success',
							// 				summary: this.terjemah.getTranslate().sukses,
							// 				detail: this.terjemah.getTranslate().pesanSuksesInputData
							// 		});
							// 		this.ngOnInit();
							// 		this.aktivIndex = 0
							// 		this.getDataTable('','',0, 10,'')
							// });
			// 		}
			// });
	}

	tambah(){
		this.reset()
		this.displayDialog = true
	}

	edit(event,data): void{
		let dataGrid = {
			id: data.id,
			bidang_id : data.bidang_id,
			role_id: data.role_id,
			nama_lengkap: data.nama_lengkap,
			email : data.email,
			telepon : data.telepon,
			is_active: data.is_active
		};
		this.form.setValue(dataGrid);
		this.displayDialog = true
	}
}
