import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Config } from '../guard';
import { Observable, Subject, throwError } from 'rxjs';
import {ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient, private router: Router, private  apiService: ApiService) { };
    private statusLogin = new Subject<boolean>();
    private dataLoginUser:  dataLoginUser
    private route: any[];
    private listRoute:any[]
    private dataLogin: any
        login(username: string, password: string, rememberMe: boolean) {
        return this.http.post<any>(Config.get().apiBackend+'/auth/admin/login', { email:username.trim(),password:password.trim()},{
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'Content-Type, Authorization',
              'Access-Control-Allow-Methods': 'POST',
              'Content-Type':'application/json'
            }
          })
        .pipe(
            map(user => {
                if (user.data.token) {
                    if (rememberMe) {
                        localStorage.setItem('currentUser', JSON.stringify(user.data.token));
                    } else {
                        sessionStorage.setItem('currentUser', JSON.stringify(user.data.token));
                    }
                    this.setDataLogin(user.token)
                    this.loginStatus(true)
                    this.apiService.get(Config.get().apiBackend+'/auth/profile').subscribe((res)=>{
                      localStorage.setItem('user-profile',JSON.stringify(res.data))
                    })
                }
                return user.token;
            })
            );
    }
    setDataLogin(data:any) {
      this.dataLogin = data
    }
    getDataLogin() {
      return this.dataLogin
    }
    flatDataArray(array) {
        let dataArray = []
        array.forEach((node, index, object) => {
            if(node.items) {
                dataArray = dataArray.concat(this.flatDataArray(node.items))
            } else {
                dataArray.push({
                    label: node.label,
                    routerLink: node.routerLink
                })
            }
        })
        return dataArray
    }
    filterListRoute(array){
        let dataArray = []
        array.forEach((data) => {
            dataArray.push(data.routerLink[0])
        })
        return dataArray
    }
    getListUrl(){
        return this.listRoute
    }
    setListRoute(value) {
        this.route = this.flatDataArray(value)
        this.listRoute = this.filterListRoute(this.route)
        this.listRoute.push('/')
        localStorage.setItem('currentUser.role', JSON.stringify(this.listRoute))
        console.log(this.listRoute)
    }
    getListRoute(){
        return this.route
    }

    setDataLoginUser(value: dataLoginUser) {
        if (value != null) {
            this.dataLoginUser = value
        } else {
            this.dataLoginUser = null
        }

    }

    getDataLoginUser() {
        return this.dataLoginUser
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.clear()
        sessionStorage.clear()
        this.setDataLoginUser(null)
        this.loginStatus(false)

        this.router.navigate(['login']);
    }
    loginStatus(value:boolean) {
        this.statusLogin.next(value);
    }

    getLoginStatus(): Observable<any> {
        return this.statusLogin.asObservable();
    }

}
interface dataLoginUser {
    namaUser:string
    kdUser:any
    kdPegawai:any
    kdProfile:any
    kdDepartemen:any
    kdKelompokUser:any
    kdRuangan:any
    pathFoto:string
    token:string
}
