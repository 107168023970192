export class Config {
	static get() {
		return {
		    apiBackend: 'https://oss-be.wadisini.id/api',
			apiBackend_local: 'http://localhost:8080/api',
			page:1,
			rows:10
		}
	}
}
